<template>
  <div>
    <van-cell-group title="主要信息">
      <van-cell title="功能室" v-model="func.val.Func_Room_Name" @click="click" is-link />
      <van-popup v-model="func.show" round position="bottom">
        <van-picker :columns="func.dat" value-key="Func_Room_Name" @confirm="confirmFunc" show-toolbar
                    @cancel="func.show = false"/>
      </van-popup>

      <van-field type="text" label="设施" v-model="func.val.Facillities" placeholder="请填写设施" readonly
                 input-align="right"/>
      <van-field type="text" label="教玩具" v-model="func.val.Room_Tools" placeholder="请填写教玩具" readonly
                 input-align="right"/>
    </van-cell-group>
    <van-cell-group title="附加信息">
      <van-cell title="类型" v-model="funcType.val" @click="funcType.show=true" is-link/>
      <van-popup v-model="funcType.show" round position="bottom">
        <van-picker :columns="funcType.dat" @confirm="confirmFuncType" show-toolbar
                    @cancel="funcType.show = false"/>
      </van-popup>

      <van-field v-if="funcType.val=='其它'" type="text" label="其它类型" v-model="model.Activity_Type"
                 placeholder="请填写类型" input-align="right"/>

      <van-field type="text" label="活动内容" v-model="model.Activity_Content" placeholder="请填写活动内容"
                 input-align="right"/>
      <div class="imgbox">
        <div class="title">上传图片</div>
        <van-uploader :after-read="afterRead" multiple v-model="picList" @delete="deleteImg"
                      max-count="6" style="margin: 10px 0px 0px 10px"/>
      </div>
    </van-cell-group>
    <van-cell-group title="整理情况">
      <van-cell title="环境" v-model="model.Environment_State" @click="environmentState.show=true" is-link/>
      <van-popup v-model="environmentState.show" round position="bottom">
        <van-picker :columns="environmentState.dat" @confirm="confirmEnvironmentState" show-toolbar
                    @cancel="environmentState.show = false"/>
      </van-popup>

      <van-cell title="设施" v-model="model.Facility_State" @click="facilityState.show=true" is-link/>
      <van-popup v-model="facilityState.show" round position="bottom">
        <van-picker :columns="facilityState.dat" @confirm="confirmFacilityState" show-toolbar
                    @cancel="facilityState.show = false"/>
      </van-popup>

      <van-cell title="教玩具" v-model="model.Tool_State" @click="toolState.show=true" is-link/>
      <van-popup v-model="toolState.show" round position="bottom">
        <van-picker :columns="toolState.dat" @confirm="confirmToolState" show-toolbar
                    @cancel="toolState.show = false"/>
      </van-popup>

      <van-field type="textarea" label="备注" v-model="model.Remark" placeholder="选择破损或损坏时备注说明情况"
                 input-align="right"/>
    </van-cell-group>
    <div style="margin: 15px">
      <van-button type="primary" size="large" icon="success" block @click="submit" :loading="submitting"
                  loading-text="正在提交。。。">确认提交
      </van-button>
      <br/>
      <van-button plain type="default" size="large" icon="cross" block @click="$router.go(-1)">取消返回</van-button>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";

export default {
  name: "edit",
  data() {
    return {
      model: {Activity_Pics:[]},
      func: {
        show: false,
        dat: [],
        val: {}
      },
      funcType: {
        show: false,
        dat: ['会议', '活动', '上课', '其它'],
        val: '',
      },
      environmentState: {
        show: false,
        dat: ['好', '差'],
      },
      facilityState: {
        show: false,
        dat: ['完好', '破损'],
      },
      toolState: {
        show: false,
        dat: ['完整', '损耗'],
      },
      submitting: false,
      picList: [],
      Activity_Pics:[],
      disabled:false,
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let guid = this.$route.query.guid
      this.disabled = !!guid
      this.getList(guid)
    },
    getList(guid){
      let self = this;
      this.whale.remote.getCollection({
        url: "/api/Mobile/FUNC/FuncRoom/GetPageList",
        completed(its) {
          self.func.dat = its.ITEMS
          if(guid){
            self.func.val = its.ITEMS.find(item=>item.RoomGuid==guid)
          }
        }
      })
    },
    click(){
      this.func.show = !this.disabled
    },
    afterRead(files) {
      let self = this
      if (!Array.isArray(files)) {
        files = [files]
      }
      files.forEach(item=>{
        let b64Arr = item.content.split(',')
        this.whale.remote.getResult({
          url: "/api/Mobile/FUNC/FuncRoomUse/UpUseOrManPicture",
          data: {b64:b64Arr[1]},
          completed(its) {
            self.Activity_Pics.push(its.DATA)
          },
        })
      })
    },
    deleteImg(files, {index}){
      this.Activity_Pics.splice(index,1)
    },
    confirmFunc(val) {
      this.func.show = false;
      this.func.val = val;
    },
    confirmFuncType(val) {
      this.funcType.show = false
      this.funcType.val = val;
    },
    confirmEnvironmentState(val) {
      this.environmentState.show = false
      this.model.Environment_State = val
    },
    confirmFacilityState(val) {
      this.facilityState.show = false
      this.model.Facility_State = val
    },
    confirmToolState(val) {
      this.toolState.show = false
      this.model.Tool_State = val
    },
    submit() {
      if (this.func.val.ID == undefined) {
        Toast.fail("请选择功能室")
        return
      }

      if (!this.funcType.val) {
        Toast.fail('请选择活动类型')
        return;
      } else {
        if (this.funcType.val !== '其它') {
          this.model.Activity_Type = this.funcType.val
        } else {
          if (!this.model.Activity_Type) {
            Toast.fail('请输入其它类型')
            return;
          }
        }
      }

      if (!this.model.Activity_Content) {
        Toast.fail("请填写活动内容")
        return
      }

      if (!this.model.Environment_State || !this.model.Facility_State || !this.model.Tool_State) {
        Toast.fail("请选择整理情况")
        return
      }
      // console.log(this.model)
      let self = this;
      self.model.Func_Room_Id = self.func.val.ID
      self.model.Activity_Pics = self.Activity_Pics.join(',')
      self.submitting = true;
      this.whale.remote.getResult({
        url: "/api/Mobile/FUNC/FuncRoomUse/CreateNew",
        data: self.model,
        finally() {
          self.submitting = false
        },
        completed() {
          self.$dialog.alert({
            message: "记录已成功提交！"
          }).then(() => {
            self.$router.go(-1);
          })
        }
      })
    }
  }
}
</script>
<style scoped>
.imgbox {
  padding: 10px;
}

.imgbox .title {
  padding: 0 6px;
  font-size: 14px;
  color: #646566;
}
</style>
